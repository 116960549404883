import { RemixiconReactIconComponentType } from 'remixicon-react';
import DoubleQuotesRIcon from 'remixicon-react/DoubleQuotesRIcon';
import { twMerge } from 'tailwind-merge';
import { useTooltipRebuild } from '../useTooltipRebuild';

const borderMap: {
  [key in FeaturedIconSize]: number;
} = {
  sm: 4,
  md: 6,
  lg: 8,
  xl: 8,
};

const innerDimensionMap: {
  [key: number]: {
    [key in FeaturedIconSize]: number;
  };
} = {
  1: {
    sm: 24,
    md: 24,
    lg: 28,
    xl: 36,
  },
  0: {
    sm: 24,
    md: 32,
    lg: 40,
    xl: 48,
  },
};

const sizeMap: {
  [key: number]: {
    [key in FeaturedIconSize]: number;
  };
} = {
  1: {
    sm: 12,
    md: 12,
    lg: 14,
    xl: 18,
  },
  0: {
    sm: 12,
    md: 16,
    lg: 20,
    xl: 24,
  },
};

const iconColorMap: {
  [key in FeaturedIconColorType]: string;
} = {
  primary: 'primary',
  grey: 'grey-700',
  success: 'success',
  danger: 'danger',
  warning: 'warning',
};
const borderColorMap: {
  [key in FeaturedIconColorType]: {
    [key in FeaturedIconThemeType]?: string;
  };
} = {
  primary: {
    'double-solid': 'blue-800',
    'double-light': 'blue-100',
  },
  grey: {
    'double-solid': 'grey-800',
    'double-light': 'grey-100',
  },
  danger: {
    'double-solid': 'red-600',
    'double-light': 'red-100',
  },
  warning: {
    'double-solid': 'yellow-700',
    'double-light': 'yellow-100',
  },
  success: {
    'double-solid': 'green-500',
    'double-light': 'green-100',
  },
};

const bgColorMap: {
  [key in FeaturedIconColorType]: {
    [key in FeaturedIconThemeType]: string;
  };
} = {
  primary: {
    'double-solid': 'primary',
    'double-light': 'blue-200',
    solid: 'primary',
    light: 'blue-100',
  },
  grey: {
    'double-solid': 'grey-700',
    'double-light': 'grey-200',
    solid: 'grey-600',
    light: 'grey-100',
  },
  danger: {
    'double-solid': 'danger',
    'double-light': 'red-200',
    solid: 'danger',
    light: 'red-100',
  },

  warning: {
    'double-solid': 'warning',
    'double-light': 'yellow-200',
    solid: 'warning',
    light: 'yellow-200',
  },
  success: {
    'double-solid': 'success',
    'double-light': 'green-200',
    solid: 'success',
    light: 'green-100',
  },
};

export type FeaturedIconColorType = 'primary' | 'grey' | 'danger' | 'warning' | 'success';

type FeaturedIconThemeType = 'solid' | 'double-solid' | 'light' | 'double-light';

type FeaturedIconSize = 'sm' | 'md' | 'lg' | 'xl';
type FeaturedIconTypeType = 'circle' | 'square';
export type FeaturedIconProps = {
  size?: FeaturedIconSize;
  color?: FeaturedIconColorType;
  theme?: FeaturedIconThemeType;
  type?: FeaturedIconTypeType;
  icon?: RemixiconReactIconComponentType;
};
export const FeaturedIcon: React.FC<Omit<React.HTMLProps<HTMLDivElement>, 'size'> & FeaturedIconProps> = ({
  size = 'lg',
  icon = DoubleQuotesRIcon,
  theme = 'double-light',
  color = 'primary',
  type = 'circle',
  ...props
}) => {
  const hasBorder = ['double-solid', 'double-light'].includes(theme);
  const borderWidth = hasBorder ? borderMap[size] : 0;
  const borderColor = hasBorder ? borderColorMap[color][theme] : '';
  const bgColor = bgColorMap[color][theme];
  const iconColor = theme === 'solid' || theme === 'double-solid' ? 'white' : iconColorMap[color];
  const Icon = icon;

  useTooltipRebuild();
  return (
    <div
      {...props}
      style={{
        padding: `${borderWidth}px`,
      }}
      className={twMerge(
        `bg-${borderColor} ${type === 'circle' ? 'rounded-full' : 'rounded-large'} ${props.className}`,
      )}
    >
      <div
        className={`bg-${bgColor} ${type === 'circle' ? 'rounded-full' : 'rounded-large'} grid place-items-center`}
        style={{
          width: innerDimensionMap[hasBorder ? 1 : 0][size],
          height: innerDimensionMap[hasBorder ? 1 : 0][size],
        }}
      >
        {/* {hasBorder.toString()} */}
        <Icon className={`text-${iconColor}`} size={sizeMap[hasBorder ? 1 : 0][size]} />
      </div>
    </div>
  );
};
