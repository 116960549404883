import Image from 'next/image';
import { CalendlyButton } from '../../../../../../shared/ui/src/lib/calendly';
export const WhyPrishaPolicy = () => {
  const data = [
    [
      'RELIABLE',
      'Commitment is at the core of our endeavour.',
      'You can avail our top-notch services with no stress as we cut the clutter with our tailored services designed by in-house industry experts.',
    ],
    [
      'CUSTOMER-CENTRIC',
      'Everything starts with a customer.',
      'We know what our customers want the most, and our company provides the best. To us, a customer is not an outsider to our business but part of our business.',
    ],
    [
      'EXPERTS',
      'Expert team with 40 years of experience.',
      'We provide our clients with an out-and-out combination of experience, expertise and creativity to maintain high service standards and ensure eminence.',
    ],
  ];

  return (
    <div className="bg-surface-grey">
      <div className="py-15 container">
        <div className="text-center">
          <p className="text-primary font-medium uppercase">Why prishapolicy</p>
          <h2 className="heading-3 xl:text-[42px]">We want you to be completely protected</h2>
        </div>
        <div className="relative grid-cols-1 items-stretch pt-10 xl:grid xl:grid-cols-2" id="why-prisha-landing">
          <div className="flex flex-1 flex-col justify-between pt-5 xl:p-12 ">
            <div>
              {data.map(([name, heading, description], idx) => {
                return (
                  <div className="mt-16 flex gap-x-3 first:mt-0" key={idx} id={'why-' + (idx + 1).toString()}>
                    <div>
                      <div className="text-primary font-medium uppercase">&nbsp;</div>
                      <h3 className="heading-4 xl:text-title-3">{idx + 1}.</h3>
                    </div>
                    <div className="flex-1">
                      <div className="text-primary font-medium uppercase">{name}</div>
                      <h3 className="heading-4 xl:text-title-3">{heading}</h3>
                      <p>{description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mt-12">
              <CalendlyButton />
            </div>
          </div>
          <div className="hidden flex-1 place-items-center p-8 xl:grid">
            <Image src={require('./family-photo.png')} className="w-full" alt="landing-family-photo" />
          </div>
        </div>
      </div>
    </div>
  );
};
