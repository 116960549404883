import { Button } from '@prishapolicy/shared/ui';
import Link from 'next/link';
import { CalendlyButton } from '../../../../../../shared/ui/src/lib/calendly';
import trophy from './trophy.webp';
export const Awards = () => {
  return (
    <div className="mb-15 bg-green-300" style={{}}>
      <div className="container grid grid-cols-1 gap-x-4 text-center xl:grid-cols-2 xl:text-left">
        <div
          className="-mt-20 hidden pt-20 xl:block"
          style={{
            backgroundImage: `url(${trophy.src})`,
            backgroundSize: 'cover',
          }}
        ></div>
        <div className="py-15">
          <h2 className="xl:text-title-1 heading-3 font-bold">Get the services from an award-winning team.</h2>
          <p className="mt-4 mb-7">
            When it comes to taking care of your insurance needs, we strive to provide you with the best services in the
            industry. With technology, our current drive aims to derive Customer WOW! Our experienced, award-winning
            team engages systems that enable us to scale from Customer Satisfaction to Customer Delight.
          </p>

          <div className="flex flex-col gap-3 xl:flex-row">
            <Link passHref legacyBehavior href={'/about/press-and-awards/'}>
              <Button text="Press & Awards" />
            </Link>
            <CalendlyButton variant="secondary" />
          </div>
        </div>
      </div>
    </div>
  );
};
