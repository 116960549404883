export const OurPartners = () => {
  return (
    <div className="pb-15 container grid grid-cols-1 xl:grid-cols-2">
      <div className="flex flex-col justify-center text-center xl:text-left">
        <div className="text-primary font-medium uppercase">Our Partners</div>
        <h2 className="heading-3 my-3 xl:w-3/4">Avoid paying out-of-pocket and safeguard your business.</h2>
        <p>
          Finding the perfect insurance policy can be daunting and overwhelming. We at PrishaPolicy have made it our
          mission to help you find a plan that covers all your needs. We have partnered with all major insurance
          companies to deliver you the best of the best.
        </p>
      </div>
      <div className="my-10  xl:my-0">
        <video src="/landing-page/partners.mp4" autoPlay playsInline controls loop muted className="w-full"></video>
      </div>
    </div>
  );
};
