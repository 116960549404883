/* eslint-disable max-lines */
import {
  Button,
  CalendlyButton,
  CalendlyContext,
  calendlyData,
  FeaturedIcon,
  HeroBackground,
  ProductMain,
  slugify,
  useWindowWidth,
} from '@prishapolicy/shared/ui';
import {
  Awards,
  BasicCard,
  CalendlyModalSSR,
  CookieModal,
  COOKIE_MODAL_DELAY,
  OurPartners,
  productCategoryIcons,
  SSRHoverCard,
  TestimonialMarquee,
  WhyPrishaPolicy,
} from '@prishapolicy/w3/ui';
import { NextSeo } from 'next-seo';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { RemixiconReactIconComponentType } from 'remixicon-react';
import AlertFillIcon from 'remixicon-react/AlertFillIcon';
import FilePaperFill from 'remixicon-react/FilePaperFillIcon';
import Folder3FillIcon from 'remixicon-react/Folder3FillIcon';
import StackFill from 'remixicon-react/StackFillIcon';
import financialSafetyNet from './financial-safety-net.png';
import fulfillLifeGoals from './fulfill-life-goals.png';
import peaceOfMind from './peace-of-mind.png';
import saveOnTaxes from './save-on-taxes.png';

const LandingHero = () => {
  const { isLg } = useWindowWidth(0);
  return (
    <HeroBackground padding={false} backgroundColor="yellow-200">
      <div className="container left-0 right-0 z-10 flex items-center pb-8  pt-16 lg:absolute xl:h-[85vh]">
        <div className=" xl:w-3/5">
          <h1 className="text-title-1 font-bold xl:text-[60px]">
            An Insurance Policy <span></span> is not just an insurance policy.
          </h1>
          <p className="mt-3 mb-8">
            It speaks a story of human ambition and determination despite the risk. We believe that “Doing something
            costs something and doing nothing costs a lot more.”
          </p>
          <div className="flex flex-col gap-3 md:flex-row">
            <Link href="https://buy.prishapolicy.com" passHref legacyBehavior>
              <Button text="Get a Quote" rightIcon="arrowRight" aria-label="Visit buy.prishapolicy.com" />
            </Link>
            <CalendlyButton variant="secondary" />
          </div>
          <div className="mt-10">
            <p>Trusted by</p>
            <div className="flex gap-5">
              {[
                ['800+', 'Corporate Clients'],
                ['1 Lakh+', 'Individuals Clients'],
              ].map(([mainText, secondaryText]) => {
                return (
                  <div key={mainText}>
                    <p className="text-title-3 xl:text-title-1 font-black">{mainText}</p>
                    <p className="body 1 xl:text-title-7"> {secondaryText}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {isLg && (
        <video
          src={'/landing-page/landing.webm'}
          className="w-full object-cover brightness-[1.15] saturate-[.80]"
          playsInline
          autoPlay
          muted
          loop
        ></video>
      )}
    </HeroBackground>
  );
};
const InsuranceProducts = () => {
  const productsRow1 = [
    ['Motor Insurance', productCategoryIcons.motor, 'red-200'],
    ['Health & Accidental', productCategoryIcons.health, 'green-200'],
    ['Travel Insurance', productCategoryIcons.travel, 'blue-200'],
    ['Liability Insurance', productCategoryIcons.liability, 'yellow-200'],
  ] as const;

  const productsRow2 = [
    ['Life Insurance', productCategoryIcons.life, 'teal-200'],
    ['Marine Insurance', productCategoryIcons.marine, 'blue-200'],
    ['Engineering Insurance', productCategoryIcons.engineering, 'teal-200'],
    ['Fire Insurance', productCategoryIcons.fire, 'red-200'],
  ] as const;

  //const productsRow3 = [
  // ['Other Insurances', productCategoryIcons.other, 'purple-100'],
  // ] as const;

  return (
    <div>
      <div className="py-15 container">
        <div className="text-center">
          <div className="text-primary font-medium uppercase">Insurance Products</div>
          <h2 className="xl:heading-1 heading-3 mt-2">Get the right product in a simpler way.</h2>
        </div>
        <div>
          <div className="mt-10 mb-2 flex flex-wrap gap-x-2 gap-y-2">
            {productsRow1.map(([text, image, bgColor], idx) => {
              return (
                <SSRHoverCard
                  name={text}
                  image={<Image src={image.src} alt={text} width={48} height={48} />}
                  bgColor={bgColor}
                  key={idx}
                  href={`/products/${slugify(text)}`}
                />
              );
            })}
          </div>

          <div className="mb-2 flex flex-wrap gap-x-2 gap-y-2">
            {productsRow2.map(([text, image, bgColor], idx) => {
              return (
                <SSRHoverCard
                  name={text}
                  image={<Image src={image.src} alt={text} width={48} height={48} />}
                  bgColor={bgColor}
                  key={idx}
                  href={`/products/${slugify(text)}`}
                />
              );
            })}
          </div>

          {/* <div className="flex flex-wrap gap-x-2 gap-y-2">
            {productsRow3.map(([text, image, bgColor], idx) => {
              return (
                <SSRHoverCard
                  name={text}
                  image={<Image src={image.src} alt={text} width={48} height={48} />}
                  bgColor={bgColor}
                  key={idx}
                  href={`/products/${slugify(text)}`}
                />
              );
            })}
          </div> */}
        </div>
      </div>
    </div>
  );
};

const Services = () => {
  const services = [
    [
      'Risk Inspection',
      'Understanding the risks of your business and mitigating them becomes crucial when you are at the helm of a business. PrishaPolicy conducts rigorous risk analysis to help you manage your business better.',
      AlertFillIcon,
    ],
    [
      'Insurance Audits',
      'An audit ensures that any business pays the optimal premium, is adequately covered through different policies, and that all clauses in the policy document are present for their portfolio.',
      Folder3FillIcon,
    ],
    [
      'Claims Settlement Services',
      'We understand that insurance is bought for the coverage of any losses in the event of an unforeseen event, but sometimes getting the claim for your insurance can be a tedious task.',
      FilePaperFill,
    ],
    [
      'Designing Insurance Programs',
      'Our wide range of experience and in-depth industry expertise allow us to craft customized programs for your business and meet your specific needs.',
      StackFill,
    ],
  ];
  return (
    <div className="relative bg-white">
      <div className="pb-15 container">
        <div className="text-center">
          <p className="text-primary font-medium uppercase">Our Services</p>
          <h2 className="xl:text-title-1 heading-3 my-2">Get the right product in a simpler way.</h2>
          <p>
            At PrishaPolicy, we aim that all our customers should be able to avoid paying out-of-pocket and still get
            their businesses safeguarded from all risks.
          </p>
        </div>
        <div className="mt-8 grid gap-10 lg:grid-cols-2 lg:gap-12">
          {services.map(([title, description, icon], idx) => {
            return (
              <div key={idx} className="flex flex-col gap-5 lg:flex-row lg:gap-6">
                <div className="flex items-start">
                  <FeaturedIcon
                    size={'lg'}
                    type="square"
                    color="primary"
                    theme="double-solid"
                    icon={icon as RemixiconReactIconComponentType}
                  />
                </div>
                <div className="flex-1">
                  <h3 className="heading-5">{title as string}</h3>
                  <p>{description as string}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export function Index() {
  const needsOfInsurance = [
    {
      title: 'Financial safety net',
      text: 'Losses are inevitable, but insurance lessens the impact of such losses by providing financial benefits for covered losses.',
      description:
        'Losses are inevitable, but insurance lessens the impact of such losses by providing financial benefits for covered losses.',
      image: financialSafetyNet,
    },
    {
      title: 'Save on taxes',
      text: 'If nothing else, you should purchase insurance simply because you get tax deductions on the premiums you pay.',
      description:
        'If nothing else, you should purchase insurance simply because you get tax deductions on the premiums you pay.',
      image: saveOnTaxes,
    },
    {
      title: 'Fulfil Life Goals',
      text: 'Some insurance plans require you to invest small amounts that will grow over time to help you to accomplish your dreams.',
      description:
        'Some insurance plans require you to invest small amounts that will grow over time to help you to accomplish your dreams.',
      image: fulfillLifeGoals,
    },
    {
      title: 'Peace of Mind',
      text: "Above all, it gives a sense of security. When you have insurance, you know you've really safeguarded yourself.",
      description:
        "Above all, it gives a sense of security. When you have insurance, you know you've really safeguarded yourself.",
      image: peaceOfMind,
    },
  ];

  const [modalState, setModalState] = useState({
    calendly: false,
    cookies: false,
  });

  useEffect(() => {
    setTimeout(() => {
      if (!localStorage.getItem('cookies')) {
        setModalState((prev) => ({ ...prev, cookies: true }));
      }
    }, COOKIE_MODAL_DELAY);
  }, []);

  return (
    <CalendlyContext.Provider
      value={{
        isOpen: modalState.calendly,
        setIsOpen: (value: boolean) => setModalState((prev) => ({ ...prev, calendly: value })),
        calendlyData: calendlyData,
      }}
    >
      <NextSeo description="At PrishaPolicy, insurance speaks a story of human ambition and determination despite the risk. We believe that “Doing something costs something and doing nothing costs a lot more." />
      <ProductMain color="yellow">
        <LandingHero />
        <InsuranceProducts />
        <WhyPrishaPolicy />
        <div className="my-15 container">
          <div className="text-center">
            <div className="text-primary mb-2 font-medium uppercase">the need for insurance</div>
            <h2 className="heading-3 xl:heading-2 mb-7">Spend less time worrying.</h2>
          </div>
          <div className="flex gap-4 overflow-scroll xl:overflow-hidden">
            {needsOfInsurance.map((need, idx) => {
              return (
                <BasicCard
                  imageSize={40}
                  title={need.title}
                  content={need.description}
                  key={idx}
                  image={need.image}
                  className="min-w-[75%] xl:min-w-0"
                />
              );
            })}
          </div>
        </div>
        <OurPartners />
        <Awards />
        <Services />
        <TestimonialMarquee />
        <CalendlyModalSSR />
      </ProductMain>
      <CookieModal
        isOpen={modalState.cookies}
        onClose={() => {
          localStorage.setItem('cookies', 'true');
          setModalState((prev) => ({ ...prev, cookies: false }));
        }}
      />
    </CalendlyContext.Provider>
  );
}

export default Index;
