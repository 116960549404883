import { PropsWithChildren, ReactNode } from 'react';
import { UIColorMapping } from '@prishapolicy/shared/ui';
import { ProductThemeContext } from './context';

export const ProductMain = ({
  color = 'red',
  ...props
}: PropsWithChildren<{
  color: keyof typeof UIColorMapping;
  children: ReactNode;
}>) => {
  return (
    <div>
      <ProductThemeContext.Provider value={UIColorMapping[color]}>{props.children}</ProductThemeContext.Provider>
    </div>
  );
};
