import { BadgeColor } from '../badge';
export const UIColorMapping = {
  red: {
    primaryShade: 'red-400',
    secondaryShade: 'red-100',
  },
  green: {
    primaryShade: 'green-300',
    secondaryShade: 'green-100',
  },
  blue: {
    primaryShade: 'blue-300',
    secondaryShade: 'blue-200',
  },
  yellow: {
    primaryShade: 'yellow-400',
    secondaryShade: 'yellow-200',
  },
  purple: {
    primaryShade: 'purple-200',
    secondaryShade: 'purple-200',
  },
  teal: {
    primaryShade: 'teal-300',
    secondaryShade: 'teal-200',
  },
} as const;
// export type UIColor = keyof typeof UIColorMapping
export const productColorMapping =
  // DON'T CHANGE THIS TYPE, OTHERWISE YOU WILL BREAK W3
  {
    'motor-insurance': 'red',
    'health-and-accidental': 'green',
    'travel-insurance': 'blue',
    'liability-insurance': 'yellow',
    'engineering-insurance': 'teal',
    'fire-insurance': 'red',
    'marine-insurance': 'blue',
    'other-insurances': 'purple',
    'life-insurance': 'teal',
  } as const;

export const productBadgeColorMapping: { [key in keyof typeof productColorMapping]: BadgeColor } = {
  'motor-insurance': 'red',
  'health-and-accidental': 'green',
  'travel-insurance': 'blue',
  'liability-insurance': 'yellow',
  'engineering-insurance': 'teal',
  'fire-insurance': 'red',
  'marine-insurance': 'blue',
  'other-insurances': 'purple',
  'life-insurance': 'teal',
};

export const leadStatusColorMap = {
  New: 'teal',
  Hot: 'red',
  Warm: 'yellow',
  Cold: 'blue',
  Dead: 'grey',
} as const;

export const accountTypeColorMap: { [key: string]: BadgeColor } = {
  corporate: 'blue',
  retail: 'teal',
} as const;

export const partnerRolesMap = {
  general: 'grey',
} as const;

export const opportunityStatusMap = {
  OPPORTUNITY: 'yellow',
  PROSPECT: 'teal',
  NEGOTIATION: 'purple',
  WON: 'green',
  LOST: 'red',
  UNQUALIFIED: 'red',
  ARCHIVED: 'red',
} as const;

export const policyStatusMap: { [key: string]: BadgeColor } = {
  DRAFT: 'yellow',
  BOOKED: 'grey',
  CANCELED: 'red',
  ARCHIVED: 'red',
};

export type UIColor = keyof typeof UIColorMapping;

export type ProductColorMappingType = keyof typeof productColorMapping;

export type StatusColorMapType = keyof typeof leadStatusColorMap;

export type OpportunityStatusMapType = keyof typeof opportunityStatusMap;
