import { Badge, BadgeColor, useWindowWidth } from '@prishapolicy/shared/ui';
import { ReactNode } from 'react';
export type HoverCardProps = {
  name?: string;
  image?: ReactNode;
  bgColor?: string;
  href?: string;
  title?: string;
  subTitle?: string;
  badge?: {
    text: string;
    theme: BadgeColor;
  };
  disabled?: boolean;
  onClick?: () => void;
};

export const HoverCard = (props: HoverCardProps) => {
  const { isLg } = useWindowWidth();
  return (
    <div
      style={{
        minHeight: isLg ? '200px' : '',
      }}
      className={`flex flex-1 flex-col ${
        props.image ? 'justify-between' : 'justify-end'
      } rounded-large cursor-pointer transition duration-300 ease-in-out bg-${
        props.bgColor ? props.bgColor : 'white'
      } border-grey-200 hover:border-primary border-2 p-6 hover:-translate-y-3 hover:drop-shadow-[-5px_5px_0_rgba(39,55,140,0.5)] ${
        props.disabled && `pointer-events-none opacity-50`
      }`}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
          return;
        }
        if (props.href) {
          window.location.href = props.href;
        }
      }}
    >
      <div>{props.image}</div>
      {props.subTitle && <p className="text-grey-700 body-1 font-thin">{props.subTitle}</p>}
      <div className="flex items-center justify-between">
        {props.title && <h3 className="text-title-3 text-grey-900 font-bold">{props.title}</h3>}
        {props.badge && <Badge text={props.badge.text} theme={props.badge.theme} />}
      </div>
      {props.name && <h3 className="lg:heading-4 heading-6" dangerouslySetInnerHTML={{ __html: props.name }}></h3>}
    </div>
  );
};
