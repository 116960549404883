import { useWindowWidth } from '@prishapolicy/shared/ui';
import CloseFillIcon from 'remixicon-react/CloseFillIcon';
import Image from 'next/image';
import Link from 'next/link';
import styles from './style.module.scss';
type CookieModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const COOKIE_MODAL_DELAY = 20000;
export const CookieModal = (props: CookieModalProps) => {
  const { isLg } = useWindowWidth();

  return (
    <div
      className={`fixed ${
        !props.isOpen && 'hidden'
      } top-4 left-4 z-[1000] h-16 w-[min(90vw_,_350px)] rounded-lg bg-white p-0 transition-all duration-[10000ms] lg:top-auto lg:bottom-10 lg:h-[160px] lg:w-[440px] ${
        styles['cookieModal']
      }`}
    >
      <div className="flex h-full gap-4 lg:gap-8">
        <div className="relative h-full">
          <div className="h-full w-10 rounded-l-lg bg-purple-100" />
          <div className={`absolute left-[20px] top-[50%] h-9 w-9 translate-y-[-50%] lg:top-12 lg:h-11 lg:w-11 `}>
            <Image src="/cookie.png" alt="icon" fill />
          </div>
        </div>
        <div className="flex w-full flex-col justify-center gap-4 bg-white p-4 lg:p-7 lg:pl-1">
          <div className="flex w-full items-start justify-between">
            <div className=" lg:text-primary text-body-1 lg:text-title-6 flex-1 text-left font-bold text-black">
              Our website uses cookies
            </div>
            <div className={`lg:text-primary cursor-pointer text-black`} onClick={props.onClose}>
              <CloseFillIcon />
            </div>
          </div>
          <div className={`text-grey-700 hidden lg:block ${!isLg && 'text-body-3'}`}>
            Our website use cookies. By continuing, we assume your permission to deploy cookies as detailed in our&nbsp;
            <Link href="/about/privacy-policy" className="text-info underline">
              Privacy Policy
            </Link>
            .
          </div>
        </div>
      </div>
    </div>
  );
};
