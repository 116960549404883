import { HoverCardProps, HoverCard } from '@prishapolicy/shared/ui';
import Link from 'next/link';

export const SSRHoverCard = (props: HoverCardProps) => {
  const { href, ...rest } = props;
  if (!href) return <HoverCard {...rest} />;
  return (
    <Link href={href} legacyBehavior>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="flex flex-1">
        <HoverCard {...rest} />
      </a>
    </Link>
  );
};
