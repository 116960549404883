import Image, { StaticImageData } from 'next/image';
import { twMerge } from 'tailwind-merge';

export type BasicCardProps = {
  title: string;
  content?: string | JSX.Element;
  imageSize?: number;
  image?: StaticImageData;
  className?: string;
  link?: string;
};

export const BasicCard = ({ imageSize = 64, ...props }: BasicCardProps) => {
  return (
    <div
      className={twMerge(
        `rounded-large border-grey-200 flex-1 border-2 bg-white p-6 ${props.className} ${
          props.link && 'cursor-pointer'
        }`,
      )}
      onClick={() => {
        if (props.link) {
          window.location.href = props.link;
        }
      }}
    >
      {props.image && (
        <div className="mb-4 flex flex-col items-start justify-end">
          <Image src={props.image} alt={props.title} width={`${imageSize}`} height={`${imageSize}`} />
        </div>
      )}
      <h3 className="text-title-7 lg:text-title-4 font-bold" dangerouslySetInnerHTML={{ __html: props.title }}></h3>
      <div className="prose lg:text-body-1 text-body-3 mt-3">
        <div>{props.content}</div>
      </div>
    </div>
  );
};

export * from './read-more';
